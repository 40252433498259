import React from "react";
import Header from "./header";
import Footer from "./footer";

import "../styles/fonts.css";
import "../styles/global.css";
import "../styles/layout.css";

import styles from "./layout.module.css";

const Layout = ({
  children,
  siteTitle,
  onHideNav,
  onShowNav,
  showNav,
  onHideContact,
  onShowContact,
  showContact,
  hidePage,
}) => (
  <div className={hidePage ? styles.pageHidden : ""}>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      onHideContact={onHideContact}
      onShowContact={onShowContact}
      showContact={showContact}
    />
    <div className={styles.content}>{children}</div>
    <Footer onHideContact={onHideContact} onShowContact={onShowContact} showContact={showContact} />
  </div>
);

export default Layout;
