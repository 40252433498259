import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import QSiteSettings from "../queries/QSiteSettings";

import logo from "../images/capacity-logo-mono.svg";
import { FaVimeoV, FaYoutube, FaInstagram } from "react-icons/fa";

import { cn } from "../lib/helpers";
import { pageTransition } from "../lib/transition-config";
import { bodyTextBlock } from "./typography.module.css";

import styles from "./footer.module.css";

const now = new Date();
const currentYear = now.getFullYear();

const Footer = () => (
  <QSiteSettings>
    {({ site }) => {
      return (
        <footer className={styles.root}>
          <div className={styles.navWrapper}>
            <div className={styles.branding}>
              <AniLink {...pageTransition} to="/">
                <img className={styles.logo} src={logo} alt={site.title} />
              </AniLink>
            </div>
            <nav className={styles.nav}>
              <ul>
                <li>
                  <AniLink {...pageTransition} to="/work/">
                    Work
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/team/">
                    Team
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/careers/">
                    Careers
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/faq/">
                    FAQ
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/contact/">
                    Contact
                  </AniLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className={styles.socials}>
            <a href="https://www.instagram.com/teamcapacity/" target="_blank">
              <FaInstagram />
            </a>
            <a href="https://vimeo.com/capacity" target="_blank">
              <FaVimeoV />
            </a>
            <a href="https://www.youtube.com/channel/UCNGvjLPU9PzpcK-1F3hydyw/" target="_blank">
              <FaYoutube />
            </a>
          </div>
          <div className={cn(styles.legal, bodyTextBlock)}>
            &copy; Capacity Corp. {currentYear}.
          </div>
        </footer>
      );
    }}
  </QSiteSettings>
);

export default Footer;
