import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default ({ children }) => (
  <StaticQuery
    query={query}
    render={({ site }) => {
      return children({ site });
    }}
  />
);
const query = graphql`
  query SiteSettingsQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      email
      phone
    }
  }
`;
