import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import QSiteSettings from "../queries/QSiteSettings";
import { cn } from "../lib/helpers";

import Icon from "./icon";
import logo from "../images/capacity-logo.svg";
import shieldIcon from "../images/shield-offwhite.svg";
import styles from "./header.module.css";
import { responsiveMicro } from "./typography.module.css";
import { pageTransition, homePageTransition } from "../lib/transition-config";

const Header = ({ siteTitle, onHideNav, onShowNav, showNav }) => (
  <QSiteSettings>
    {({ site }) => {
      return (
        <header className={styles.root}>
          <div className={styles.wrapper}>
            <div className={styles.branding}>
              <AniLink {...homePageTransition} to="/">
                <img className={styles.logo} src={logo} alt={siteTitle} />
              </AniLink>
            </div>
            <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
              <Icon symbol="hamburger" />
            </button>

            <nav className={cn(styles.nav, showNav && styles.showNav)}>
              <ul className={responsiveMicro}>
                <li>
                  <AniLink {...pageTransition} to="/work/">
                    Work
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/team/">
                    Team
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/careers/">
                    Careers
                  </AniLink>
                </li>
                <li>
                  <AniLink {...pageTransition} to="/faq/">
                    FAQ
                  </AniLink>
                </li>
                <li className={styles.contactLink}>
                  <AniLink {...pageTransition} to="/contact/">
                    Contact
                  </AniLink>
                </li>
              </ul>
            </nav>
            <AniLink
              {...pageTransition}
              to="/contact/"
              className={cn(responsiveMicro, styles.contactButton)}
            >
              <img className={styles.shieldIcon} src={shieldIcon} alt="Contact Button" />
              <span>Contact</span>
            </AniLink>
          </div>
        </header>
      );
    }}
  </QSiteSettings>
);

export default Header;
