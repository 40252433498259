export const pageTransition = {
  duration: 0.75,
  cover: true,
  bg: "#04b4ba",
  direction: "right",
};

export const homePageTransition = {
  duration: 0.75,
  cover: true,
  bg: "#04b4ba",
  direction: "up",
};

export const splashScreenAnimationDuration = 425;
